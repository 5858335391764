import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import AnotherPage from './components/AnotherPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermCondition from './components/TermCondition';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* Home page */}
        <Route path="/another" element={<AnotherPage />} /> {/* Another page */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* PrivacyPolicy page */}
        <Route path="/term-condition" element={<TermCondition />} /> {/* TermCondition page */}
      </Routes>
    </Router>
  );
}

export default App;
